import React from 'react'
import styles from './Home.module.css';
import img from '../../Assets/3removebg.png'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <img src={img} alt="" />
      </div>
      <div className={styles.items}>
        <h2>Eccovenus</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ut laudantium natus harum, reiciendis, officia tempora doloribus laborum quis, saepe vero sapiente iusto? Suscipit, possimus fugit repellendus eos dignissimos quod.</p>
        <Link to={'/insoles'} className={styles.link}>See detial</Link>
      </div>
    </div>
  )
}

export default Home
