import React, { useState } from 'react'
import { RiImageAddLine } from "react-icons/ri";
import styles from './AddModal.module.css'
import axios from 'axios';

const AddModel = ({closeModal}) => {
  const [file, setFile] = useState('');
  const [files, setFiles] = useState('');
  const [sex, setSex] = useState('');
  const [size, setSize] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setFiles(e.target.files);
  }

  async function createNewPost(ev) {
    setIsLoading(true);
    // alert('suc');
    ev.preventDefault();
  
    const data = new FormData();
    data.append('file', files[0]);
    data.append('size', size);
    data.append('sex', sex);

    axios.post('https://venusecco.zyberapi.site/post', data)
    .then(() => {
      window.location.reload(false);
      closeModal(false);
      setIsLoading(false);
    }).catch((e) => alert(e));
  
    // try {
    //   await fetch('https://venusecco.zyberapi.site/post', {
    //     method: 'POST',
    //     body: data,
    //     credentials: 'include',
    //   }).then(() => {
    //     window.location.reload(false);
    //     closeModal(false);
    //     setIsLoading(false);
    //   }).catch((e) => alert(e));
    // } catch (error) {
    //   console.error('Error occurred during fetch:', error);
    // }
  }

  return (
    <div className='blur_container'>
      <div className='modal'>
        <h2>Бараа нэмэх</h2>
          <input 
            type="file" 
            id="img" 
            onChange={handleChange}
            className={styles.file}
          />
          <label for='img'>
            { files === '' ? 
                <div><RiImageAddLine className={styles.icon} size={50} /> </div>
              : <img src={file} className={styles.photo} alt="" />
            }
          </label>
          <label>
            Хүйс:
            <br />
            <input 
              type="text" 
              placeholder='Хүйс'
              onChange={(e) => setSex(e.target.value)}
            />
          </label>
          <label>
            Хэмжээ:
            <br />
            <input 
              type="text" 
              placeholder='Хэмжээ'
              onChange={(e) => setSize(e.target.value)}
            />
          </label>
          <div className="two-line">
            <button className='button' onClick={(ev) => createNewPost(ev)} style={{color: 'green'}}>Нийтлэх</button>
            <button className='button' onClick={closeModal} style={{color: 'red'}}>Цуцлах</button>
          </div>
      </div>
    </div>
  )
}

export default AddModel
