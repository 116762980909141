import React, { useEffect, useState } from 'react'
import styles from './Insoles.module.css'
// import img from '../../Assets/3.png'
import { IoBagAddSharp } from "react-icons/io5";
import AddModel from '../../Modal/AddModal/AddModal';
import axios from 'axios';
import { RiDeleteBin5Fill } from "react-icons/ri";
import DeleteModal from '../../Modal/DeleteModal/DeleteModal';

const Insoles = () => {
  const [isModal, setIsModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [post, setPost] = useState([]);
  const [id, setId] = useState('');

  useEffect(() => {
    axios.get('https://venusecco.zyberapi.site/post')
    .then((e) => setPost(e.data))
    .catch((e) => alert(e))
  })

  function modal() {
    setIsModal(!isModal);
  }

  function deleteModal() {
    setIsDelete(!isDelete);
  }

  function clickDelete() {
    axios.delete('https://venusecco.zyberapi.site/post/' + id)
    .then(() => {
        window.location.reload(false);
    })
    .catch((e) => alert(e));
  }

  return (
    <>
      { isDelete ? <DeleteModal deletePost={clickDelete} closeModal={deleteModal}/> : 
          isModal ? <AddModel closeModal={modal}/> :
            <div className={styles.container}>
              <h1>Улавчнууд</h1>
              <p>Та өөрийн хүссэн улавчаа сонгоно уу!</p>
              <div className={styles.items}>
                <div onClick={modal} className={styles.add}>
                  <IoBagAddSharp size={100} />
                </div>
                {post.map((e) => (
                    <div className={styles.card}>
                      <img src={'https://venusecco.zyberapi.site/' + e.cover} alt="" />
                      <div className={styles.info}>
                          <h2>Хэмжээ: {e.size}</h2>
                          <RiDeleteBin5Fill onClick={() => {
                              deleteModal();
                              setId(e._id);
                            }} color='red' size={30}/>
                          <h2>{e.sex}</h2>
                      </div>
                    </div>
                ))}
              </div>
            </div>
        }
    </>
  )
}

export default Insoles
