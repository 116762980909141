import React from 'react'

const DeleteModal = ({closeModal, deletePost}) => {
  return (
    <div className='blur_container'>
        <div className='modal'>
            <h2>Та устгах уу?</h2>
            <div className="two-line">
                <button onClick={deletePost} className='button' style={{color: 'red'}}>Устгах</button>
                <button onClick={closeModal} className='button' style={{color: 'green'}}>Цуцлах</button>
            </div>
        </div>
    </div>
  )
}

export default DeleteModal
